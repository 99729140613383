<template>
  <el-dialog title="添加角色" :visible="true" :before-close="onClose" width="800px" class="usermgr-win-select-role">
    <el-container>
      <el-main style="padding:0">
        <std-table ref="auth_table" :cols="tableConfig1.cols" :data="roles" disable-selection style="height:300px;"
          :pageindex.sync="tableConfig1.pageIndex" :pagesize.sync="tableConfig1.pageSize" :dataTotal="tableConfig1.total" remotePaging
          @refresh="onRefresh">
          <template #action="{row}">
            <el-link @click="onLookDetail(row.EB_ROLE_ID)" style="margin-right:5px;">查看</el-link>
            <el-checkbox v-if="!row.exists" @change="onSelectRole(row,$event)" size="mini" :value="!!selRoles[row.EB_ROLE_ID]">
              <span :class="{isnocheck:!selRoles[row.EB_ROLE_ID]}">选中</span>
            </el-checkbox>
            <span v-else class="exist">(重复)</span>
          </template>
        </std-table>
        <div class="checklist">
          <el-divider content-position="left">已选角色</el-divider>
          <span v-if="newrole.length==0" class="emptytips">尚未选择</span>
          <el-tag v-for="(r,i) in newrole" :key="r.role_id" closable @close="onRemoveSel(i)">{{r.desc}}</el-tag>
        </div>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" :disabled="newrole.length==0" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      tableConfig1: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        cols: [
          { label: '角色名', prop: 'EB_ROLE_DESC', width: 150 },
          { label: '角色说明', prop: 'EB_ROLE_REMARK' },
          { label: '创建时间', prop: 'EB_CREATE_DATETIME', width: 170 },
          { label: '操作', format: 'action', width: 110 }
        ]
      },
      roles: [],
      newrole: []
    }
  },
  computed: {
    selRoles() {
      let obj = {}
      this.newrole.forEach(p => obj[p.role_id] = true)
      return obj;
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onRefresh() {
      let { pageIndex, pageSize } = this.tableConfig1
      this.Bus.ActionFunc("UserMgr-GetAllRoleInfo", {
        pageIndex: pageIndex,
        pageSize: pageSize,
        user_id:this.data.user_id
      }, data => {
        this.$set(this, 'roles', data.list)
        this.$set(this.tableConfig1, 'total', data.total)
      })
    },
    onSave() {
      if (this.newrole.length == 0) {
        this.Bus.msg_waring('请选择要添加的角色')
        return;
      }
      let postData = {
        user_id: this.data.user_id,
        roles: this.newrole.map(p => p.role_id)
      }
      this.Bus.ActionFunc("UserMgr-AppendNewRole", postData, data => {
        this.Bus.msg_success('新增成功!')
        this.$emit("success", {})
      })
    },
    onLookDetail(role_id) {

    },
    onSelectRole(row, checked) {
      if (checked) {
        this.newrole.push({
          role_id: row.EB_ROLE_ID,
          desc: row.EB_ROLE_DESC
        })
      } else {
        for (let i = 0; i < this.newrole.length; i++) {
          if (this.newrole[i].role_id == row.EB_ROLE_ID) {
            this.newrole.splice(i, 1)
            break;
          }
        }
      }
    },
    onRemoveSel(index){
      this.newrole.splice(index,1)
    }
  },
  mounted() {
    let me = this
    this.onRefresh()
  }
}
</script>
<style lang="scss">
.usermgr-win-select-role {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
        .isnocheck {
          color: #c4c4c4;
        }
        .el-table .el-table__row td{
          padding:5px 0;
        }
        .checklist {
          .el-divider{
            margin:15px 0;
          }
          .el-tag{
            margin-right: 5px;
          }
          border: 1px solid gray;
          border-left: 0;
          border-right: 0;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
        .exist {
          color: #59a1bd;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
