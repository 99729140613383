<template>
  <el-container class="usermgr-detail">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card v-if="form.wait===true" style="font-size:13px;">
        <div class="c_red" v-if="form.error!=null" v-text="form.error"></div>
        <div class="c_gray" v-else>加载中...</div>
      </el-card>
      <el-card class="detail-info">
        <el-form ref="detail_form" class="form-jg5" label-width="75px" label-position="right" size="mini">
          <el-form-item label="用户编号">
            <el-input placeholder="系统自动生成" disabled v-model="form.EB_USER_ID"></el-input>
          </el-form-item>
          <el-form-item label="登录账号">
            <el-input placeholder="必填" v-model="form.EB_LOGIN_CODE"></el-input>
          </el-form-item>
          <el-form-item label="用户名">
            <el-input placeholder="显示的用户名称" v-model="form.EB_USER_DESC"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" v-if="isCreate">
            <el-input show-password placeholder="请输入密码" v-model="form.EB_LOGIN_PWD" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="角色分配">
            <el-button type="text" size="mini" @click="onAddRole">添加</el-button>
            <div>
              <el-tag v-for="(r,role_index) in form.Roles" :key="r.EB_ROLE_ID" closable :class="onRemoveRole(r,role_index)">
                <span v-text="r.EB_DESC"></span>
              </el-tag>
            </div>
          </el-form-item> -->
          <el-form-item label="状态">
            <el-radio-group v-model="form.EB_STATE">
              <el-radio-button label="Normal">正常</el-radio-button>
              <el-radio-button label="Disable">禁用</el-radio-button>
              <el-radio-button label="Lock">锁定</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2}" placeholder="非必填" v-model="form.EB_REMARK">
            </el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <div v-if="isCreate">等待创建</div>
            <div v-else v-text="form.EB_CREATE_DATETIME"></div>
          </el-form-item>
          <el-form-item label="变更时间" v-if="!isCreate">
            <div v-text="form.EB_LASTMODIFY_DATETIME"></div>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="role-list">
        <template #header>
          角色分配
          <div class="buttons">
            <el-button type="text" size="mini" @click="onAddRole">添加角色</el-button>
            <el-button type="text" size="mini">查看当前拥有的权限</el-button>
          </div>
        </template>
        <std-table ref="auth_table" :cols="tableConfig1.cols" :data="roles" disable-selection forceRefresh>
          <template #action="{row,rowindex}">
            <el-link icon="el-icon-view" @click="onLookDetail(row.EB_ROLE_ID)">权限</el-link>
            <el-link icon="el-icon-delete" @click="onRemoveRole(row.EB_ROLE_ID,rowindex)" style="margin-left:5px;">移除</el-link>
          </template>
        </std-table>
      </el-card>
      <component v-if="dialog.show" :is="dialog.type" :data="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card class="upload-box" style="min-height:100px;">
        <template #header>
          操作
        </template>
        <div style="">
          <el-button type="primary" size="mini" @click="onSave">
            <span v-if="isCreate">创建用户</span>
            <span v-else>更新资料</span>
          </el-button>
          <div style="font-size: 13px;padding: 10px 0;color: #626161;">
            修改角色权限保存时,用户端将立即受到权限变更的影响
          </div>
          <el-button type="primary" size="mini" v-if="!isCreate" @click="pwdchange.show=true">修改密码</el-button>
          <div class="flexbox" v-if="pwdchange.show" style="margin-top:5px;">
            <el-input show-password placeholder="请输入新密码" v-model="pwdchange.value" size="mini" class="flex-1"></el-input>
            <el-button type="primary" size="mini" @click="onChangePwd">提交</el-button>
            <el-button type="primary" size="mini" @click="pwdchange.show=false">取消</el-button>
          </div>
          <div v-if="!isCreate" style="font-size: 13px;padding: 10px 0;color: #626161;">
            修改密码后对应用户会强制登出,需重新登录
          </div>
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
import winSelectRole from './win-select-role'
import md5 from 'js-md5'
export default {
  components: {
    winSelectRole
  },
  data() {
    return {
      navData: [
        { text: '系统管理', path: '/SysMgr' },
        { text: '用户管理', path: '/UserMgr/Index' },
        '用户明细'
      ],
      user_id: '',
      isCreate: true,
      form: {
        wait: true,
        error: null
      },
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      pwdchange: {
        show: false,
        value: ''
      },
      roles: []
    }
  },
  computed: {
    tableConfig1() {
      return {
        cols: [
          { label: '角色名', prop: 'EB_ROLE_DESC', width: 150 },
          { label: '角色说明', prop: 'EB_REMARK' },
          { label: '添加时间', prop: 'EB_CREATE_DATETIME', width: 170 },
          { label: '操作', format: 'action', width: 130 }
        ]
      }
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    onAddRole() {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-select-role',
        data: {
          user_id: this.$route.params.user_id
        },
        event() {
          me.$set(me, 'dialog', {
            show: false
          })
          me.RefreshRoles();
        }
      })
    },
    RefreshRoles() {
      let me = this
      me.Bus.ActionFunc("UserMgr-GetDetailRoles", {
        user_id: this.user_id
      }, roledata => {
        me.$set(me, 'roles', roledata.roles)
      })
    },
    onRemoveRole(role_id, index) {
      let me = this
      this.Bus.msg_confirm('确定要删除移除该角色吗?', _ => {
        me.Bus.ActionFunc("UserMgr-RemoveRole", {
          role_id: role_id,
          user_id: this.user_id
        }, data => {
          //从列表中移除
          this.roles.splice(index, 1)
          //返回剩余的角色列表,如果与实际不符则刷新列表,否则只是从列表移除当前角色
          let flag = false
          if (this.roles.length != data.roles.length) {
            flag = true
          } else {
            //判断同行是否相同
            for (let i = 0; i < data.roles.length; i++) {
              if (this.roles[i].EB_ROLE_ID != data.roles[i]) {
                flag = true
                break;
              }
            }
          }
          if (flag) {//需要更新
            //重新加载角色列表
            me.RefreshRoles()
          }
        })
      })
    },
    onSave() {
      let me = this
      let form = this.form
      let isCreate = this.isCreate
      let valiResult = this.Bus.validData(form, {
        require: [
          { prop: 'EB_LOGIN_CODE', name: '登录账号' },
          { prop: 'EB_USER_DESC', name: '用户名称' },
          { prop: 'EB_STATE', name: '状态' },
        ],
        func: [
          {
            name: '登录密码',
            func() {
              if (isCreate && me.Bus.isEmpty(form.EB_LOGIN_PWD)) {
                return '请输入登录密码'
              }
            }
          }
        ]
      })
      if (!valiResult) {
        return
      }
      let postData = {
        login_code: form.EB_LOGIN_CODE,
        desc: form.EB_USER_DESC,
        state: form.EB_STATE,
        remark: form.EB_REMARK,
        roles: form.Roles.map(p => p.EB_ROLE_ID)
      }
      if (!isCreate) {
        postData.user_id = form.EB_USER_ID
      } else {
        postData.pwd = md5(form.EB_LOGIN_PWD)
      }
      this.Bus.msg_confirm(`确定要提交吗?`, _ => {
        me.Bus.ActionFunc(isCreate ? 'UserMgr-CreateDetail' : 'UserMgr-UpdateDetail', {
          data: postData
        }, data => {
          me.Bus.msg_success("创建成功!")
          if (isCreate) {
            postData.user_id = data.user_id
          }
          //跳转刷新自身到编辑页
          this.$router.push({
            name: 'UserMgrDetail',
            params: {
              user_id: postData.user_id
            }
          })
          //跳转回列表页
          // me.$router.push({
          //   name: "UserMgrIndex",
          //   params: {
          //     newid: postData.user_id
          //   }
          // })
        })
      })
    },
    onChangePwd() {
      let newpwd = this.pwdchange.value
      let me = this;
      if (this.Bus.isEmpty(newpwd)) {
        this.Bus.msg_waring("请输入新的密码");
        return
      }
      this.Bus.msg_confirm('确定要更新该用户的登录密码吗?', _ => {
        me.Bus.ActionFunc("UserMgr-ChangePwd", {
          user_id: me.form.EB_USER_ID,
          pwd: md5(newpwd)
        }, data => {
          me.Bus.msg_success('操作成功!');
          this.pwdchange.show = false
        })
      })
    }
  },
  mounted() {
    let me = this
    let userid = this.$route.params.user_id;
    //判断是新增还是编辑
    let create = this.$route.params.create;
    if (create !== true && this.Bus.isEmpty(userid)) {
      this.$router.push({ path: '/UserMgr/Index' })
      return
    }
    if (this.Bus.isEmpty(userid)) {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.user_id = userid
    }
    //如果是编辑则加载数据
    if (!this.isCreate) {
      this.Bus.ActionFunc('UserMgr-GetDetail', {
        user_id: userid
      }, data => {
        this.$set(this, 'form', data.user)
        this.$set(this, 'roles', data.roles)
      }, (data, err) => {
        me.form.error = `内容加载失败请重新打开:[${err.status}]${err.message}`
      })
    } else {
      this.$set(this, 'form', {
        EB_USER_ID: '',
        EB_LOGIN_CODE: '',
        EB_USER_DESC: '',
        EB_STATE: 'Normal',
        EB_LOGIN_PWD: '',
        EB_REMARK: '',
        Roles: []
      })
    }
  }
}
</script>
<style lang="scss">
.usermgr-detail {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    padding: 0px 20px 10px 0px !important;
    .role-list {
      .buttons {
        float: right;
      }
      > .el-card__body {
        padding: 0;
      }
    }
    > .el-card + .el-card {
      margin-top: 10px;
    }
  }
}
</style>